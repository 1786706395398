<template>
  <div class="product">
    <div class="banner"></div>
    <div class="productList">
      <div class="content">
        <el-collapse
          v-model="activeName"
          accordion
          class="wow fadeIn"
          data-wow-duration="1s"
          data-wow-delay="0.5s"
        >
          <el-collapse-item title="推荐产品" name="1">
            <ul
              class="list wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.5s"
            >
              <li v-for="(item, index) in productList" :key="index">
                <img :src="item.img" alt />
                <p>{{ item.name }}</p>
              </li>
            </ul>
          </el-collapse-item>
          <el-collapse-item title="热销产品" name="2"></el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <div class="black-block">
      <div class="content">
        <div class="desc">
          <p>高效电机制造专家</p>
          <p>批量生产欧、美、日三大标准体系电机</p>
        </div>
      </div>
    </div>
    <div class="fullMeasureList">
      <div class="content">
        <div class="menu">
          <ul>
            <li
              v-for="(item, index) in menuList"
              :key="index"
              :class="current == index ? 'active' : ''"
              @mouseenter="changeMenu(index)"
            >
              <div>{{ item.name }}</div>
            </li>
          </ul>
        </div>
        <div class="measureList">
          <ul class="list">
            <li
              v-for="(item, index) in fullList"
              :key="index"
              class="wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.5s"
            >
              <img :src="item.img" alt />
              <p>{{ item.name }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
  data() {
    return {
      activeName: "1",
      current: 0,//
      productList: [
        {
          img: "https://28139699.s21i.faiusr.com/4/ABUIABAEGAAgn7zSjAYoyOukuQEwgQc4-ws!450x450.png.webp",
          name: "某某牌超好用的打印纸",
        },
        {
          img: "https://img2.baidu.com/it/u=1441919072,2493407525&fm=26&fmt=auto",
          name: "某某牌超好用的打印纸",
        },
        {
          img: "https://img2.baidu.com/it/u=1441919072,2493407525&fm=26&fmt=auto",
          name: "某某牌超好用的打印纸",
        },
        {
          img: "https://img2.baidu.com/it/u=4225631030,3055146044&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=751",
          name: "某某牌超好用的打印纸",
        },
        {
          img: "https://img2.baidu.com/it/u=4225631030,3055146044&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=751",
          name: "某某牌超好用的打印纸",
        },
        {
          img: "https://img2.baidu.com/it/u=4225631030,3055146044&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=751",
          name: "某某牌超好用的打印纸",
        },
        {
          img: "https://img2.baidu.com/it/u=4225631030,3055146044&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=751",
          name: "某某牌超好用的打印纸",
        },
        {
          img: "https://img2.baidu.com/it/u=4225631030,3055146044&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=751",
          name: "某某牌超好用的打印纸",
        },
      ],
      menuList: [
        {
          id: 0,
          name: "高效电机",
        },
        {
          id: 1,
          name: "高压电机",
        },
        {
          id: 2,
          name: "低压电机",
        },
        {
          id: 3,
          name: "防爆电机",
        },
        {
          id: 4,
          name: "调速电机",
        },
      ],
      fullList: [
        {
          img: "https://28139699.s21i.faiusr.com/4/ABUIABAEGAAgn7zSjAYoyOukuQEwgQc4-ws!450x450.png.webp",
          name: "某某牌超好用的打印纸",
        },
        {
          img: "https://img2.baidu.com/it/u=4225631030,3055146044&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=751",
          name: "某某牌超好用的打印纸",
        },
        {
          img: "https://img2.baidu.com/it/u=4225631030,3055146044&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=751",
          name: "某某牌超好用的打印纸",
        },
        {
          img: "https://img2.baidu.com/it/u=4225631030,3055146044&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=751",
          name: "某某牌超好用的打印纸",
        },
        {
          img: "https://img2.baidu.com/it/u=4225631030,3055146044&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=751",
          name: "某某牌超好用的打印纸",
        },
        {
          img: "https://img2.baidu.com/it/u=4225631030,3055146044&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=751",
          name: "某某牌超好用的打印纸",
        },
        {
          img: "https://img2.baidu.com/it/u=4225631030,3055146044&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=751",
          name: "某某牌超好用的打印纸",
        },
        {
          img: "https://img2.baidu.com/it/u=4225631030,3055146044&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=751",
          name: "某某牌超好用的打印纸",
        },
        {
          img: "https://28139699.s21i.faiusr.com/4/ABUIABAEGAAgn7zSjAYoyOukuQEwgQc4-ws!450x450.png.webp",
          name: "某某牌超好用的打印纸",
        },
        {
          img: "https://img2.baidu.com/it/u=4225631030,3055146044&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=751",
          name: "某某牌超好用的打印纸",
        },
        {
          img: "https://img2.baidu.com/it/u=4225631030,3055146044&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=751",
          name: "某某牌超好用的打印纸",
        },
        {
          img: "https://img2.baidu.com/it/u=4225631030,3055146044&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=751",
          name: "某某牌超好用的打印纸",
        },
      ],
    };
  },
  mounted() {
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
    });
    wow.init();
  },
  methods: {
    // 列表切换事件
    changeMenu(index) {
      this.current = index;
    },
  },
};
</script>

<style lang="less" scoped>
.product {
  .banner {
    background: url("//20601220.s61i.faiusr.com/2/AD0IhLPpCRACGAAgsPeQ7QUo59XWgQQwgA84rAI.jpg")
      no-repeat 50% 50%;
    height: 300px;
  }
  .productList {
    background: rgba(248, 248, 248, 1);
    .content {
      width: 1200px;
      margin: 0 auto;
      padding: 72px 0 0 0;
      box-sizing: border-box;
      height: 926px;
      ::v-deep.el-collapse-item__content {
        padding-bottom: 10px;
      }
      ::v-deep.el-collapse-item__header {
        padding-left: 20px;
        font-size: 16px;
        &.is-active {
          color: #ffffff;
          background: #1e50ae;
        }
      }
      .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: 34px 21px 0;
        li {
          width: 260px;
          margin: 0px 10px;
          img {
            width: 100%;
            height: 260px;
            border: 1px solid rgb(240, 238, 238);
            cursor: pointer;
            transition: all 0.4s ease;
            image-rendering: -webkit-optimize-contrast;
            &:hover {
              -webkit-transform: translate(0, -10px);
              -moz-transform: translate(0, -10px);
              transform: translate(0, -10px);
            }
          }
          p {
            text-align: center;
            font-size: 14px;
            color: #666666;
            margin: 10px 0;
          }
        }
      }
    }
  }
  .black-block {
    height: 180px;
    background: url(//20601220.s61i.faiusr.com/2/AD0IhLPpCRACGAAg9fqQ7QUoyf6EjwUwgA84tAE.jpg)
        repeat top,
      transparent;
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
      color: #fff;
      height: 180px;
      .desc {
        p {
          &:nth-of-type(1) {
            font-size: 34px;
            font-weight: bold;
          }
          &:nth-of-type(2) {
            font-size: 22px;
            color: rgb(200, 204, 213);
            margin-top: 10px;
          }
        }
      }
    }
  }
  .fullMeasureList {
    .content {
      background: #fff;
      width: 1200px;
      margin: 0 auto;
      padding-top: 30px;
      padding-bottom: 74px;
      box-sizing: border-box;
      .menu {
        padding-left: 218px;
        ul {
          display: flex;
          li {
            padding: 0 24px;
            text-align: center;
            line-height: 50px;
            margin: 0 12px;
            font-size: 16px;
            color: #666;
            box-sizing: border-box;
            width: 130px;
            &.active {
              color: #fff;
              background: #1e50ae;
              border-radius: 50px;
            }
          }
        }
      }
      .measureList {
        .list {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          margin: 34px 21px 0;
          li {
            width: 260px;
            margin: 0px 10px;
            img {
              width: 100%;
              height: 260px;
              border: 1px solid rgb(240, 238, 238);
              cursor: pointer;
              transition: all 0.4s ease;
              image-rendering: -webkit-optimize-contrast;
              &:hover {
                -webkit-transform: translate(0, -10px);
                -moz-transform: translate(0, -10px);
                transform: translate(0, -10px);
              }
            }
            p {
              text-align: center;
              font-size: 14px;
              color: #666666;
              margin: 10px 0;
            }
          }
        }
      }
    }
  }
}
</style>